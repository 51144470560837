//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        // array: [],
        // dateFrom: '',
        // dateTo: '',
      },
    };
  },

  computed: {
    ...mapState('tradersRequisites', ['filterLists']),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradersRequisites', ['setFilters']),
  },
};
