//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';
import User from '~/components/User.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Filters, Modal, User },

  data() {
    return {
      editingRequisite: {
        show: false,
        data: {},
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('tradersRequisites', ['page', 'pages', 'data', 'sort']),
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  // async mounted() {
  //   await this.loadP2pPaymentMethods();
  // },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('tradersRequisites', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      deleteRequisiteAction: 'deleteRequisite',
      editRequisiteAction: 'editRequisite',
      createRequisiteAction: 'createRequisite',
    }),
    ...mapActions('tradersDevices', {
      setDevicesFilters: 'setFilters',
    }),
    // ...mapActions('p2p', ['loadP2pPaymentMethods']),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openCreateRequisiteModal() {
      this.editingRequisite.show = true;
      this.editingRequisite.data = null;
      this.editingRequisite.isCreating = true;
    },

    openModalEditRequisite(row) {
      this.editingRequisite.show = true;
      this.editingRequisite.data = { ...row };
      this.editingRequisite.isCreating = false;
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingRequisite;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create requisite?'
          : 'Are you sure you want to edit requisite?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              await this.createRequisiteAction(input);
            } else {
              await this.editRequisiteAction(input);
            }
            this.editingRequisite.show = false;
            await this.loadDataAction({});
            if (isCreating) {
              this.setSuccessNotification('Requisite created');
            } else {
              this.setSuccessNotification('Requisite edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    deleteRequisite(row) {
      this.confirmAction({
        title: 'Are you sure you want to delete requisite?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteRequisiteAction({ requisitesId: row.id, userId: row.userId });
            await this.loadDataAction({});
            this.setSuccessNotification(`Requisite ${row.id} deleted`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    async openDeviceById(deviceId) {
      this.setDevicesFilters({ id: deviceId });
    },

    statusClass(status) {
      return this.constant.traders.REQUISITE_TYPE[status] === this.constant.traders.REQUISITE_TYPE.REQUISITE_STATUS_ENABLED
        ? 'success--text'
        : 'error--text';
    },

    paymentMethodStatusClass(status) {
      return status === this.constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
