//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        searchString: '',
        id: null,
        // array: [],
        // dateFrom: '',
        // dateTo: '',
      },
    };
  },

  computed: {
    ...mapState('tradersDevices', {
      filterLists: 'filterLists',
      stateFilters: 'filters',
    }),
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
    'stateFilters.id': {
      handler(newId) {
        this.filters.id = newId;
      },
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradersDevices', ['setFilters']),
  },
};
